.fileUpload {
    position: relative;
}

.fileUploadArea {
    position: relative;
    background-color: var(--white);
    appearance: none;
    outline: none !important;
    font-size: 1rem;
    font-style: normal;
    line-height: 20px;
    color: var(--gray-800);
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
}
.fileUploadArea:active,
.fileUploadArea:focus {
    border-color: var(--primary-300);
    box-shadow: 0px 0px 0px 4px var(--primary-100);
}
.fileUploadArea:hover {
    background-color: var(--primary-50);
}
.fileUploadArea.error,
.fileUploadArea:hover.error {
    background-color: var(--error-50);
}
.fileUploadArea.error:active,
.fileUploadArea.error:focus {
    border-color: var(--error-300);
    box-shadow: 0px 0px 0px 4px var(--error-50);
}
.fileUploadArea.dragging {
    background-color: rgba(0, 112, 243, 0.1);
}
.fileUploadArea.disabled {
    cursor: not-allowed;
}
.fileUploadArea.disabled,
.fileUploadArea:hover.disabled {
    background-color: var(--gray-50);
}
.fileUploadArea.disabled:active,
.fileUploadArea.disabled:focus {
    border-color: var(--gray-300);
    box-shadow: 0px 0px 0px 4px var(--gray-100);
}

.fileUploadArea.disabled .featuredIcon,
.fileUploadArea.disabled:hover .featuredIcon {
    background-color: var(--gray-100);
    color: var(--gray-400);
}
.fileUploadArea.uploaded .featuredIcon,
.fileUploadArea.uploading .featuredIcon,
.fileUploadArea.dragging .featuredIcon,
.fileUploadArea:hover .featuredIcon {
    background-color: var(--primary-200);
    color: var(--primary-600);
}
.fileUploadArea.error .featuredIcon,
.fileUploadArea:hover.error .featuredIcon {
    background-color: var(--error-100);
    color: var(--error-600);
}

.storedFileExists {
    background-color: var(--primary-200);
    color: var(--primary-600);
}

.fileUploadArea .fileUploadAreaContainer {
    position: relative;
    display: flex;
    padding: 16px;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid var(--gray-300);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    z-index: 1;
}
.fileUploadArea.error .fileUploadAreaContainer,
.fileUploadArea:hover.error .fileUploadAreaContainer {
    border-color: var(--error-300);
}
.fileUploadArea.dragging .fileUploadAreaContainer {
    border: 1px dashed var(--primary-500);
}

.fileUploadArea .uploadProgress {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-50);
    border-radius: 8px;
    z-index: 0;
}
.fileUploadArea.uploading:hover .uploadProgress {
    background-color: var(--primary-100);
}
.fileUpload.hasErrors .fileUploadArea .uploadProgress {
    background-color: #FFEAE9;
}
.fileUploadArea.uploaded .uploadProgress {
    background-color: transparent;
}

.fileUploadArea .featuredIconContainer {
    display: flex;
    align-items: center;
}

.fileUploadInformation {
    display: flex;
    gap: 4px;
    width: 100%;
    justify-content: space-between;
}
.fileUploadInformationWrapper {
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.filename {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto;
    gap: 0px;
}
.filename .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.additionalFileDetails > * {
    vertical-align: middle;
}
.additionalFileDetails > *:not(:last-child) {
    margin-right: 4px;
}

.fileUpload.hasErrors .additionalFileDetails .uploadedCheckIcon {
    display: none;
}
.additionalFileDetails .uploadedCheckIcon {
    color: var(--success-600);
}

.deleteButton {
    margin-top: -7px;
    margin-right: -7px;
}
