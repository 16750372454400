.sourceAvatar {
    margin-top: -.22rem;
    display: inline-block;
    max-height: 1.4rem;
    border-radius: 50%;
}

.description {
    max-width: 300px;
    width: 80vw;
    text-wrap: initial;
}

.sourceIconContainer {
    flex-basis: 1.7rem;
}
