.inputContainer {
    margin-top: 0.25rem;
    width: 100%;
    display: block;
    font-size: 0.875rem;
    border: 1px solid var(--gray-300);
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.inputContainer:focus {
    outline: none;
    border-color: var(--primary-300);
    box-shadow: 0 0 0 2px var(--primary-100);
}

.inputContainer:disabled {
    background-color: var(--gray-100);
    color: var(--gray-400);
}

.inputContainer.inputDisabled:disabled {
    background-color: unset;
    color: unset;
}
