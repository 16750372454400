.Dropdown {
    position: absolute;
    opacity: 0;
    min-width: 100%;
    bottom: calc(-1 * var(--spacing-sm));
    transform: translateY(100%);
    pointer-events: none;
}
.Dropdown.isActive {
    opacity: 1;
    pointer-events: auto;
    z-index: 50;
}
.Dropdown.left {
    left: 0;
}
.Dropdown.right {
    right: 0;
}

.Dropdown .dropdownCard {
    border-radius: 8px;
    border: 1px solid var(--gray-100);
    max-height: 400px;
    max-width: max-content;
    overflow-y: auto;
}

.Dropdown.fullWidth .dropdownCard {
    max-width: 100%;
}

.TimeInput .Dropdown .dropdownCard {
    max-height: 220px;
}
