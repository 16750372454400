.loadingContainer {
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;
    font-size: 14px;
    line-height: 14px;
    color: var(--gray-500);
    white-space: nowrap;
    padding: 18px 0;
    transition: var(--animation-duration) ease-in-out padding;
}

.isSubmitting,
.isSuccessful {
    padding: 18px 8px;
}

.loadingItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    opacity: 0;
    height: 0;
    transform: translateX(8px);
    transition:
        var(--animation-duration) ease-in-out opacity,
        var(--animation-duration) ease-in-out transform;
}

.loadingContainer.isSuccessful .savingSuccessText {
    transition-delay: var(--animation-duration);
}

.loadingContainer.isSubmitting .savingText {
    opacity: 1;
    transform: translateX(0);
}

.loadingContainer.isSuccessful .savingSuccessText {
    transition-delay: var(--animation-duration);
}

.loadingContainer.isSuccessful .savingSuccessText {
    opacity: 1;
    transform: translateX(0);
}

.loadingIcon {
    width: 20px;
    height: 20px;
}

@media only screen and (max-width: 400px) {
    .loadingContainer {
        justify-content: center;
        padding: 0;
    }
    .isSubmitting,
    .isSuccessful {
        padding: 18px 8px;
    }

    .loadingItem {
        justify-content: center;
        transform: translateX(0) translateY(8px);
    }
    .loadingContainer.isSubmitting .savingText {
        transform: translateY(0);
    }
    .loadingContainer.isSuccessful .savingSuccessText {
        transform: translateY(0);
    }
}