.modal {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.checkboxLabel {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--gray-800);
    font-size: var(--font-size-md);
}

.listContainer {
    font-size: var(--font-size-sm);
    color: var(--gray-500);
    margin-left: 1.75rem;
}

.list {
    display: grid;
    gap: 4px;
    margin-top: 1rem;
    font-weight: 400;
}

.list li:first-child {
    margin-bottom: 10px;
}

.tenantSelect {
    color: var(--gray-100);
}

.selectContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 8px;
}

.tenantBadgeContainer {
    display: flex;
    gap: 4px;
}

.tenantBadge {
    background-color: var(--success-700);
    color: var(--white);
    border: none;
}

.buttonDisabled {
    cursor: not-allowed;
}
