.additionalDetails {
    color: var(--gray-500);
    font-size: 14px;
    display: flex;
    gap: 8px
}

.dotContainer {
    display: inline-block;
    line-height: 17px;
}

.dotContainer::before {
    content: '•';
}
