.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.xxs {
    width: 24px;
    height: 24px;
    padding: 6px;
}

.xs {
    width: 32px;
    height: 32px;
    padding: 8px;
}

.sm {
    width: 40px;
    height: 40px;
    padding: 10px;
}

.md {
    width: 48px;
    height: 48px;
    padding: 12px;
}

.lg {
    width: 56px;
    height: 56px;
    padding: 14px;
}

.xl {
    width: 64px;
    height: 64px;
    padding: 16px;
}

.xxl {
    width: 72px;
    height: 72px;
    padding: 18px;
}

.xxxl {
    width: 80px;
    height: 80px;
    padding: 20px;
}

.xxsIcon {
    width: 12px;
    height: 12px;
}

.xsIcon {
    width: 16px;
    height: 16px;
}

.smIcon {
    width: 20px;
    height: 20px;
}

.mdIcon {
    width: 24px;
    height: 24px;
}

.lgIcon {
    width: 28px;
    height: 28px;
}

.xlIcon {
    width: 32px;
    height: 32px;
}

.xxlIcon {
    width: 36px;
    height: 36px;
}

.xxxlIcon {
    width: 40px;
    height: 40px;
}

.gray {
    background: var(--gray-100);
    color: var(--gray-700);
}

.info {
    background: var(--primary-100);
    color: var(--primary-600);
}

.warning {
    background: var(--warning-100);
    color: var(--warning-950);
}

.error {
    background: var(--error-100);
    color: var(--error-600);
}

.success {
    background: var(--success-100);
    color: var(--success-950);
}
