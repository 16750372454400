.closeButton {
    margin-top: var(--spacing-md);
}

.horizontalNavigationTabsWrapper {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.titleSpacing {
    margin-top: var(--spacing-sm);
}

.selectedContents {
    transition: 200ms height ease-in-out;
    display: flex;
}
