.list {
    margin-top: var(--spacing-md);
    list-style-type: disc;
    list-style-position: inside;
}

.list li {
    margin-top: var(--spacing-sm);
    margin-left: var(--spacing-md);
}

.boldList {
    margin-top: var(--spacing-lg);
}

