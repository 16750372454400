.InputField {
    position: relative;
}

.InputField .iconBefore,
.InputField .iconAfter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    display: flex;
}

.InputField .iconBefore {
    left: 14px;
}

.InputField .iconAfter {
    right: 14px;
}

.InputField input {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    background: var(--white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    appearance: none;
    outline: none !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-800);
    width: 100%;
}

/* Sizes */
.xs input {
    font-size: 12px;
    padding: 4px 10px;
}

.sm input {
    font-size: 14px;
    padding: 6px 12px;
}

.md input {
    font-size: 14px;
    padding: 8px 14px;
}

.lg input {
    padding: 8px 16px;
    font-size: 16px;
}

.xl input {
    padding: 10px 18px;
    font-size: 16px;
}

.xxl input {
    padding: 14px 26px;
    font-size: 18px;
}

.InputField.hasIconBefore input {
    padding-left: 42px;
}

.InputField.hasIconAfter input {
    padding-right: 42px;
}

.InputField input::placeholder {
    color: var(--gray-400);
}

.InputField input:active,
.InputField input:focus {
    border-color: var(--primary-300);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary-100);
}

.InputField input:disabled {
    color: var(--gray-400);
    border-color: var(--gray-300);
    background: var(--gray-50);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: not-allowed;
}
