:root {
    --tooltip-y-pos: calc(100% + 12px);
    --tooltip-x-pos: calc(50% - 20px);
}

.tooltipContainer {
    position: relative;
    width: 24px;
    height: 24px;
    padding: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.iconContainer {
    width: 20px;
    height: 20px;
    color: var(--gray-800);
}

.tooltip {
    z-index: 9999;
    position: absolute;
    width: auto;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: var(--gray-900);
    color: var(--white);
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
    opacity: 0;
    pointer-events: none;
    touch-action: none;
}

.sm {
    width: 192px;
}

.md {
    width: 256px;
}

.lg {
    width: 320px;
}

.show {
    opacity: 1;
    transition: opacity ease-in 0.2s;
    transition-delay: 0.1s;
}

.tooltip::after {
    width: 16px;
    z-index: 9999;
    content: " ";
    position: absolute;
    transform: translateX(-50%);
    border-style: solid;
}

.top {
    bottom: var(--tooltip-y-pos);
}

.top::after {
    top: 100%;
    left: 50%;
    border-width: 6px 8px 0 8px;
    border-color: var(--gray-900) transparent transparent transparent;
}

.bottom {
    top: var(--tooltip-y-pos);
}

.bottom::after {
    bottom: 100%;
    left: 50%;
    border-width: 0 8px 6px 8px;
    border-color: transparent transparent var(--gray-900) transparent;
}

.left {
    right: var(--tooltip-x-pos);
}

.left::after {
    left: calc(100% - 20px);
}

.right {
    left: var(--tooltip-x-pos);
}

.right::after {
    left: 20px;
}
