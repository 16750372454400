.grid {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
}

.listingCard {
    flex: 1;
}

.conflict {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.conflictHomeseeker {
    position: relative;
    cursor: pointer;
    flex: 1;
}

.conflictHomeseeker.selected {
    border-color: var(--success-700)!important;
}

/* The padding is to make the button a bit easier to click */
.sidePanelButton {
    position: absolute;
    top: .5rem;
    right: .5rem;
    height: 2rem;
    width: 2rem;
    border-radius: 10px;
    text-align: center;
    line-height: 2rem;
}
