/* TODO: make a component for these grids */
.gridMd {
    display: grid;
    gap: var(--spacing-md);
    text-align: left;
}
.gridLg {
    width: 720px;
    max-width: 100%;
    display: grid;
    gap: var(--spacing-lg);
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.categoryOptions label,
.typeOptions label {
    display: inline-block;
}
