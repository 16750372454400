.container {
    display: grid;
    grid-template-columns: max-content minmax(min-content, max-content);
    align-items: flex-start;
    gap: 12px;
}

.checkboxInputContainer {
    position: relative;
    width: 20px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxInput {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    width: 20px !important;
    height: 20px !important;
    background-color: var(--white) !important;
    border: 1px solid var(--gray-300) !important;
    border-radius: 6px !important;
    appearance: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.checkboxInput:hover {
    background-color: var(--primary-50) !important;
    border: 1px solid var(--primary-600) !important;
}

.checkboxInput:focus {
    border: 1px solid var(--primary-300) !important;
    box-shadow: 0 0 0 4px var(--primary-100) !important;
}

.checkboxInput:disabled {
    background-color: var(--gray-100) !important;
    border: 1px solid var(--gray-200) !important;
}

.checkboxInput:checked {
    background-color: var(--primary-50) !important;
    border: 1px solid var(--primary-600) !important;
    border-radius: 4px !important;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-800);
    pointer-events: none;
}

.checkboxInput:disabled + .iconContainer {
    color: var(--gray-300);
}

.checkIcon {
    width: 12px;
    height: 12px;
}
