.buttonsContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}

.link {
    cursor: pointer;
}

.agentRowButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
}

.pencilIcon {
    height: 32px;
    color: var(--gray-500);
    cursor: pointer;
}

.activelink {
    color: var(--primary-500)!important;
    text-decoration: none;
}

.disabledlink {
    color: var(--gray-500)!important;
    text-decoration: none!important;
    pointer-events: auto;
}

.deleteMeModalContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
