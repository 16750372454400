.Select {
    display: inline-block;
}

.Select.fullWidth {
    width: 100%;
}

.SelectContainer {
    position: relative;
    user-select: none;
}

.Select .Label {
    display: block;
}

.Select .Placeholder {
    color: var(--gray-400);
}

.Select .Button {
    justify-content: space-between;
    text-align: left;
}

.Select.fullWidth .Button {
    width: 100%;
}

.Select .CustomButton {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 7px 14px;
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    background: var(--white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 100%;
    gap: 8px;
    cursor: text;
}

.Select .cursorPointer {
    cursor: pointer;
}

.Select .CustomButton:hover,
.Select .CustomButton:hover .SelectInput .input,
.Select .Button:hover .SelectInput .input {
    background: var(--gray-50);
}

.Select.isOpen .CustomButton,
.Select.isOpen .Button {
    border-color: var(--primary-300);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary-100);
}

.Select .CustomButton .SelectInput {
    display: flex;
    align-items: center;
    flex: 1 1 0;
}

.Select .CustomButton .SelectInput .input,
.Select .Button .SelectInput .input {
    display: inline-block;
    padding: 0;
    border: none;
    box-shadow: none;
    appearance: none;
    outline: none;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-800);
    width: 100%;
    height: 1.75rem;
    pointer-events: none;
}

.CustomButton.disabled, .SelectInput.disabled, .disabled {
    border-color: var(--gray-300);
    background-color: var(--gray-50);
    pointer-events: none;
}

.disable {
    cursor: not-allowed;
}

.HomeseekerSelector.Select .CustomButton .SelectInput .input {
    width: 240px;
}

.Select .arrowDownIcon {
    transform: scaleY(1);
    transition: 100ms ease-in-out transform;
    display: block;
    cursor: pointer;
    color: var(--gray-900);
}
.Select.isOpen .arrowDownIcon {
    transform: scaleY(-1);
}
.Select .CustomButton .iconAfter {
    display: flex;
    align-items: center;
}

.Select .SelectWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-sm);
}

.Select .SelectWrapper.hasIconBefore .iconBeforeWrapper {
    display: flex;
    align-items: center;
}

.DropdownHeader {
    position: sticky;
    background: var(--white);
    top: 0;
    padding: 11px 14px;
    z-index: 2;
    border-bottom: 1px solid var(--gray-100);
}

.items,
.items .item {
    list-style: none;
}

.items .item {
    position: relative;
    display: flex;
    background: var(--white);
    color: var(--gray-700);
    padding: 10px 14px;
    cursor: pointer;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
}

.Select.fullWidth .items .item {
    white-space: normal;
}

.items .item.unselectable {
    cursor: default;
    pointer-events: none;
    touch-action: none;
}

.items .item.disabled {
    color: var(--gray-300);
    cursor: not-allowed;
}

.items .item.hidden {
    display: none;
    visibility: hidden;
}

.items .item:hover:not(.unselectable),
.items .item:hover:not(.disabled),
.items .item.selected,
.items .item.highlighted {
    background: var(--gray-50);
}

.items .item.disabled:hover {
    background: var(--white);
}

.items .item.loadingItem {
    justify-content: center;
}

.items .item .checkLineIcon {
    margin-left: auto;
    opacity: 0;
    max-width: fit-content;
}

.items .item.selected .checkLineIcon {
    opacity: 1;
}


.Select .secondary-destructive {
    color: var(--error-600);
    border: 1px solid var(--error-300);
}

.secondary-destructive:hover {
    color: var(--error-900);
    background: var(--error-50);
    border-color: var(--error-300);
}

.secondary-destructive.selected,
.secondary-destructive:active,
.secondary-destructive:focus {
    box-shadow: 0px 0px 0px 4px var(--error-50);
}

.secondary-destructive:disabled {
    color: var(--error-300);
    background: var(--error-50);
    border-color: var(--error-300);
    box-shadow: none;
}
