/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
    text-align: left;
}
.gridLg {
    width: 720px;
    max-width: 100%;
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.dateInput {
    margin-top: 0;
}

.incomeInputContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.additionalIncomeInputContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.grossIncomeInput::-webkit-outer-spin-button,
.grossIncomeInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.grossIncomeInput[type=number] {
    -moz-appearance: textfield;
}

.additionalIncomeInput::-webkit-outer-spin-button,
.additionalIncomeInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.additionalIncomeInput[type=number] {
    -moz-appearance: textfield;
}
