.container {
    width: 100%;
    display: grid;
    gap: 16px;
}

.inputContainer {
    display: grid;
    grid-template-columns: max-content minmax(min-content, max-content);
    align-items: flex-start;
}

.radioButtonContainer {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radioButtonInput {
    width: 20px;
    height: 20px;
    display: grid;
    justify-content: center;
    align-content: center;
    border: 1px solid var(--gray-300);
    border-radius: 50%;
    color: #fff;
    background-color: #fff;
    appearance: none;
    margin: 0;
}

.radioButtonInput:checked {
    border: 1px solid var(--primary-600);
}

.radioButtonInput::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: var(--primary-600);
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.radioButtonInput:checked::before {
    transform: scale(1);
}

.radioButtonInput:is(:checked, :hover),
.radioButtonInput:is(:checked, :hover)::before {
    box-shadow: none;
    border: 1px solid var(--primary-600) !important;
}
.radioButtonInput:active,
.radioButtonInput:focus,
.radioButtonInput:active::before,
.radioButtonInput:focus::before {
    border-color: var(--primary-300);
    box-shadow:
        0px 1px 2px 0px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px var(--primary-100);
}

.label {
    padding-left: 12px;
}

.label > div {
    display: inline-block;
}

.radioButtonInput:checked.disabled {
    border: none !important;
}

.disabled.radioButtonInput::before {
    background-color: var(--gray-300);
    border: none;
}

.disabled.radioButtonInput:is(:checked, :hover)::before {
    border: none !important;
}

.radioButtonInput.disabled {
    background-color: var(--gray-200);
}

.disabled {
    cursor: not-allowed;
    position: relative;
}

.disabled * {
    pointer-events: none;
    color: var(--gray-400);
}
