.settingsFooter {
    width: 100%;
    padding: var(--spacing-md) var(--spacing-5xl);
    border-top: 1px solid #e8e9ec;
    position: sticky;
    bottom: 0;
    background: #ffffff;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    gap: var(--spacing-md)
}
