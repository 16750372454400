.card {
    display: grid;
    gap: .5rem;
    padding: 1rem;
    font-weight: 500;
    color: var(--gray-500);
}
.card strong {
    color: var(--gray-800);
}
