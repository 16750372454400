/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
    text-align: center;
}
.gridLg {
    width: 720px;
    max-width: 100%;
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeIcon {
    width: 48px;
    height: 48px;
    color: var(--error-600);
}

.textLeft {
    text-align: left;
}

.unorderedList {
    margin-left: 44px;
}

.unorderedList li:not(:last-child) {
    margin-bottom: 8px;
}
