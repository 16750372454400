.root {
    border-color: var(--gray-300);
    border-radius: 8px;
    margin-top: 0.25rem;
}

.root:focus {
    border-color: var(--primary-300);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--primary-100);
}

.fullWidth {
    width: 100%;
}
