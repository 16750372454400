.incomeSourceCard {
    display: grid;
    grid-template-rows: 1fr;
    padding: var(--spacing-md);
    transition:
        250ms cubic-bezier(0.4, 0, 0.2, 1) opacity,
        500ms cubic-bezier(0.4, 0, 0.2, 1) padding 250ms,
        500ms cubic-bezier(0.4, 0, 0.2, 1) grid-template-rows 250ms;
}

.incomeSourceCard.isDeleting {
    opacity: 0;
    grid-template-rows: 0fr;
    padding-top: 0px;
    padding-bottom: 0px;
}
.incomeSourceCard.isDeleting .gridMd {
    overflow: hidden;
}

.gridMd {
    display: grid;
    gap: var(--spacing-md);
}

.gridSm {
    display: grid;
    grid-template-columns: auto max-content;
    gap: var(--spacing-sm);
    align-items: center;
}

.textContainer {
    height: auto;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
