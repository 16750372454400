.contentContainer {
    display: flex;
    justify-content: center;
}

.gridLg {
    width: 720px;
    max-width: 100%;
    display: grid;
    gap: var(--spacing-lg);
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}
