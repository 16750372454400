.Badge {
    display: inline-flex;
    padding: 3px 8px 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 22.5rem;
    border: 1px solid transparent;
    color: #000;
}

.label {
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.Badge > div {
    display: flex;
    height: 1.25rem;
    align-items: center;
}

.New, .BeingAssessed {
    background-color: var(--primary-50);
    color: var(--primary-700);
}

.OnWaitingList {
    background-color: var(--warning-50);
    color: var(--warning-900);
}

.Rejected, .DeclinedInvitationToViewing {
    background-color: var(--error-50);
    color: var(--error-700);
}

.Approved, .AcceptedInvitationToViewing, .FilledInExtraInfoForm, .IsListingTenant{
    background-color: var(--success-50);
    color: var(--success-700);
}

.InvitedToViewing, .ReceivedExtraInfoForm {
    background-color: var(--primary-50);
    color: var(--primary-700);
}

.ScreeningSent, .ScreeningSentWithCoTenants {
    background-color: var(--primary-50);
    color: var(--primary-600);
}

.ScreeningNotCompleted, .ScreeningNotCompletedWithCoTenants {
    background-color: var(--error-50);
    color: var(--error-700);
}
