.container {
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    image-rendering: pixelated;
}
