:root {
    --animation-duration: 200ms;
}

.screeningNavigation {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: center;
    row-gap: 8px;
    column-gap: 8px;
    transition: var(--animation-duration) ease-in-out column-gap;
}

.noColumnGap {
    column-gap: 0;
}

.buttonsContainer {
    display: flex;
    gap: 8px;
}
