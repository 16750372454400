.emptyDiv {
    display: none;
}

/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
    justify-items: center;
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
}
.gridLg {
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.selectContainer {
    width: 138px;
}

.listingCard {
    display: none;
}

.submitButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.inputGrid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
}

.buttonMargin {
    margin-top: 10px;
}

.label {
    display: flex;
    align-items: center;
    min-width: 136px;
    padding-right: 40px;
    font-weight: 500;
}
.select {
    min-width:138px;
}

.amountsOfPetsTitle {
    padding: 16px 0;
}

@media (min-width: 768px) {
    .emptyDiv {
        display: block;
    }
    .cardsGrid {
        grid-template-columns: auto 1fr;
    }
    .listingCard {
        display: block;
    }
}
