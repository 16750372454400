.container {
    margin-bottom: 6px;
}

.container:last-child {
    margin-bottom: 0;
}

.label {
    display: inline;
    width: 100%;
    font-size: var(--font-size-md);
    font-weight: 500;
    line-height: 24px;
    color: var(--gray-800, #2C364F);
    white-space: pre-wrap;
}

.required {
    color: var(--error-600, #E53E3E);
}

.tooltipContainer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    margin-left: 2px;
}
