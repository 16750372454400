.mainContainer {
    z-index: 999;
    margin-top: 46px;
}

.bulkActionHeader {
    padding: 16px;
}

.bulkActionContainer a {
    display: grid;
    grid-template-columns: 2rem 1fr;
    padding-left: 16px;
    padding-right: 16px;
    white-space: wrap;
}

.bulkActionContainer i {
    font-size: 16px;
    color: var(--gray-800);

}

.bulkActionContainer div {
    font-size: 16px;
    color: var(--error-600)
}

.bulkActionButton {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    transition: box-shadow 0.2s, border-color 0.2s;
    border: 1px solid var(--gray-300);
    color: var(--gray-400);
}

.bulkActionFocus {
    color: var(--gray-900);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    transition: box-shadow 0.2s, border-color 0.2s;
    border: 1px solid var(--gray-300);
}

.bulkActionFocus:focus {
    border-color: var(--primary-300);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 0 0 4px var(--primary-100);
}

