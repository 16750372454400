/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
}
.gridLg {
    max-width: 720px;
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.qrCodeContainer {
    height: 160px;
    display: flex;
    justify-content: center;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}
