.mainContainer {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: var(--spacing-lg);
    padding-bottom: var(--spacing-lg);
    padding-left: var(--spacing-5xl);
    padding-right: var(--spacing-5xl);
    border-bottom-width: 1px;
    border-color: var(--gray-200);
}

.addressContainer {
    flex: 1 1 0%;
    min-width: 0px;
}

.addressHeader {
    font-family: Plus Jakarta Sans, Inter;
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--gray-800);
    line-height: 2.5rem;
}

.postalCode {
    color: var(--gray-400);
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.switchContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.switchGroup {
    display: flex;
    align-items: center;
    justify-items: end;
    justify-content: flex-end;
    padding-bottom: var(--spacing-sm);
}

.contactFormButtonGroup {
    display: flex;
    gap: var(--spacing-sm);
    justify-content: flex-end;
}

.switchText,
.switchTextDisabled {
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.switchTextDisabled:hover {
    cursor: not-allowed;
}

.switchLabel {
    font-size: 1rem;
    line-height: 1.5rem;
}

.switchBody {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    height: 1.5rem;
    width: 2.75rem;
    border-width: 2px;
    border-color: transparent;
    border-radius: 9999px;
    cursor: pointer;
    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
}

.switchBody.on {
    background-color: var(--primary-600);
}
.switchBody.on:hover{
    background-color: var(--primary-700);
}

.switchBody.off {
    background-color: var(--gray-100);

}

.switchBody.off:hover {
    background-color: var(--gray-200);
}

.switchBody.disabled {
    background: var(--gray-100);
    pointer-events: none;
}

.switchBody.disabled:hover {
    background: var(--gray-100);
}

.switchBody.on:focus, .switchBody.off:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;

    box-shadow: 0 0 0 4px var(--primary-100);
}

.switchBody.off, .switchBody.on {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 4px transparent;
}

.switchDot {
    pointer-events: none;
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 9999px;
    background: white;
    box-shadow: 0 1px 2px 0 var(--gray-100);
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
}

.switchDot.on {
    transform: translate(1.25rem);
}

.switchDot.off {
    transform: translate(0px);
}

.disabled {
    color: var(--gray-400);
}

.icon i{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.25rem;
}
