.sendMessageButton {
    margin-top: var(--spacing-md);
    justify-content: flex-end;
    display: flex;
}

@media (max-width: 768px) {
    .sendMessageButton button {
        width: 100%;
    }
}

.lineBreaker {
  white-space: break-spaces;
}
