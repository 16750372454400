.container {
    position: relative;
    width: 100%;
    display: flex;
    padding: 10px 0 22px 0;
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    background: var(--white);
    cursor: text;
}

.active {
    border-color: var(--primary-300);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05), 0 0 0 4px var(--primary-100);
}

.disabled {
    border-color: var(--gray-300);
    background: var(--gray-50);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    cursor: not-allowed;
}

.textArea {
    width: 100%;
    padding: 0 14px;
    background: none;
    appearance: none;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-light);
    color: var(--gray-800);
    line-height: 24px;
    resize: none;
    scrollbar-width: thin;
}

.textArea::placeholder {
    color: var(--gray-400);
}

.textArea:disabled {
    color: var(--gray-400);
    cursor: not-allowed;
}

.counter {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--gray-400);
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    padding: 6px;
}
