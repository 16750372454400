.panelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--gray-50);
    padding: 12px 16px;
    border-bottom: 1px solid var(--gray-300);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    user-select: none;
    cursor: pointer;
}
.panelHeader.collapsed {
    border-color: transparent;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.arrowIcon {
    transform: scaleY(-1);
    transition: 100ms ease-in-out transform;
}

.arrowIcon.collapsed {
    transform: scaleY(1);
}

.attendeesContainer {
    display: grid;
    gap: 16px;
    padding: 16px;
}
