.screeningCoTenantsContent {
    display: flex;
    justify-content: center;
}

/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
    justify-items: center;
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
}
.gridLg {
    width: 100%;
    max-width: 720px;
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.coTenantsForm {
    display: grid;
    gap: var(--spacing-lg);
}

.loadingButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
