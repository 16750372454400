/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
}
.gridLg {
    width: 720px;
    max-width: 100%;
    display: grid;
    gap: var(--spacing-lg);
}

.contentContainer {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.footer {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: center;
    row-gap: 8px;
    column-gap: 8px;
    transition: var(--animation-duration) ease-in-out column-gap;
}

.footer.noColumnGap {
    column-gap: 0;
}

.buttonsContainer {
    display: flex;
    gap: 8px;
}

@media only screen and (max-width: 400px) {
    .footer {
        flex-direction: column;
        row-gap: 8px;
        column-gap: 0;
        transition: var(--animation-duration) ease-in-out row-gap;
    }

    .footer.noColumnGap {
        row-gap: 0;
    }

    .buttonsContainer {
        flex-direction: column-reverse;
        width: 100%;
    }
}
