.button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 16px;
    background: var(--white);
    border: 1px dashed var(--primary-700);
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: var(--primary-500);
    outline: none;
}

.button:hover {
    background: var(--primary-50);
    border: 1px dashed var(--primary-500);
    color: var(--primary-600);
}

.button:focus {
    border: 1px dashed var(--primary-300);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05), 0 0 0 4px var(--primary-100);
}

.button:disabled {
    background: var(--gray-200);
    border: 1px dashed var(--gray-400);
    color: var(--gray-400);
}

.featuredIcon {
    border: 1px solid var(--primary-700);
    background: var(--white);
    color: var(--primary-600);
}

.button:hover .featuredIcon {
    border: 1px solid var(--primary-500);
    background: var(--primary-200);
    color: var(--primary-600);
}

.button:focus .featuredIcon {
    border: 1px solid var(--primary-300);
}

.button:disabled .featuredIcon {
    border: 1px solid var(--gray-400);
    background: var(--gray-300);
    color: var(--gray-400);
}
