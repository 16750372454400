:root {
    --icon-size: 1.5rem;
}

.highlightBlock {
    width: 100%;
    display: grid;
    grid-template-columns: var(--icon-size) 1fr;
    gap: var(--spacing-md);
    padding: var(--spacing-md);
    border-radius: 8px;
}

.highlightBlock {
    background-color: var(--gray-50);
    border: 1px solid var(--gray-200);
}

.highlightBlock.info {
    background-color: var(--primary-100);
    border: 1px solid var(--gray-200);
}

.highlightBlock.warning {
    background-color: var(--warning-50);
    border: 1px solid var(--gray-200);
}

.highlightBlock.error {
    background-color: var(--error-50);
    border: 1px solid var(--gray-200);
}

.highlightBlock .icon {
    color: var(--gray-800);
}

.highlightBlock.info .icon {
    color: var(--primary-600);
}

.highlightBlock.warning .icon {
    color: var(--warning-800);
}

.highlightBlock.error .icon {
    color: var(--error-600);
}

.highlightBlock .icon i {
    display: block;
    font-size: var(--icon-size);
    line-height: 1em;
}
