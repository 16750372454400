/* TODO: make a component for these grids */
.gridSm {
    display: grid;
    gap: var(--spacing-sm);
}
.gridMd {
    display: grid;
    gap: var(--spacing-md);
    text-align: center;
}
.gridLg {
    width: 720px;
    max-width: 100%;
    display: grid;
    gap: var(--spacing-lg);
}
.gridXl {
    display: grid;
    gap: var(--spacing-xl);
}

.contentContainer {
    display: flex;
    justify-content: center;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxIcon {
    width: 48px;
    height: 48px;
    color: var(--success-600);
}

.textLeft {
    text-align: left;
}
